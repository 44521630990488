import React, { useEffect, useState } from "react";
import { fetchInstruments } from "../../api/apiCalls";
import { GenericTable } from "../genericTable/GenericTable";

export const BatsInstruments = ({ orderBook }) => {
  const [instrumentsList, setInstrumentsList] = useState(null);
  const columnDefs = [
    { headerName: "Bloomberg Code", field: "bloombergCode" },
    { headerName: "RIC Code", field: "ricCode" },
    { headerName: "ISIN", field: "isin" },
    { headerName: "Currency", field: "currency" },
    { headerName: "Exchange (Primary)", field: "exchangePrimary" },
    { headerName: "Company Name", field: "companyName" },
  ];

  return (
    <>
      <GenericTable
        columnDefs={columnDefs}
        isInstruments={BatsInstruments}
        orderBook={orderBook}
      />
    </>
  );
};
