import { Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import "./style.css";
import {
  fetchInstruments,
  fetchParticipants,
  fetchTrades,
} from "../../api/apiCalls";
import { useEffect } from "react";
import { processStringToArray } from "../../utils/utilsFunctions";
import { onCellEditReq } from "./utils/onCellEdit"
import { generateRandomId } from "../genericTable/utils/generateId"
import { formatNumber } from "../genericTable/utils/numberFormatter"
export const GenericTable = ({
  rowData = null,
  columnDefs,
  isInstruments = false,
  isParticipants = false,
  isWorkspace = false,
  isHistory = false,
  orderBook = null,
  setRowData = null,
  setSelectedRows = null,
  selectedRows,
  tradeType
}) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [domLayout, setDomLayout] = useState("autoHeight");


  const gridStyle = useMemo(
    () => ({
      marginTop: !isWorkspace ? "25px" : null,
      borderRadius: "8px",
      overflow: "hidden",
      height: isWorkspace || isHistory ? "75vh" : "80vh",
    }),
    [isWorkspace, isHistory],
  );
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      cellDataType: false,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      headerClass: "header-center",
    };
  }, []);

  const getServerSideDatasource = useCallback(
    () => ({
      getRows: async (params) => {
        try {
          // Fetch data from the API
          const startRow = params.request.startRow;
          const endRow = params.request.endRow;
          // Await the fetchInstruments API call
          const data = isInstruments
            ? await fetchInstruments(orderBook, startRow, endRow)
            : isParticipants
            ? await fetchParticipants(orderBook, startRow, endRow)
            : isHistory
            ? await fetchTrades(orderBook, startRow, endRow)
            : [];

          // Pass the data to the grid
          params.success({
            rowData: data.rows,
            rowCount: data.totalCount,
          });
        } catch (error) {
          console.error("Error fetching rows:", error);
          // Notify the grid of the failure
          params.fail();
        }
      },
    }),
    [orderBook, isInstruments, isParticipants, isHistory],
  );

  const handlePasteEvent = useCallback((event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      event.preventDefault(); // Prevent default paste behavior
  
      navigator.clipboard
        .readText()
        .then((pastedData) => {
          const cleanedData = pastedData.replace(/\r/g, '');
          const parsedData = processStringToArray(cleanedData);
          parsedData.forEach(item => {
            let dateParts = item.settlementDate.split('/');
            item.settlementDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        });
          setRowData((prev) => {
            const oldData = [...prev];
            const startingId = oldData.length + 1;
  
            const remappedParsedData = parsedData.map((row, index) => {
              const sanitizedRow = Object.fromEntries(
                Object.entries(row).map(([key, value]) => [key, value || ""])
              );
              const tradReportId = generateRandomId(5)
              return {
                id: startingId + parsedData.length - 1 - index,
                ...sanitizedRow,
                tradeId: null,
                basketId: null,
                status: "New",
                execTime: null,
                batsId: null,
                batsResponse: null,
                venue: orderBook,
                trade_report_id: tradReportId,
              };
            });
            return [...remappedParsedData, ...oldData];
          });
        })
        .catch((err) => {
          console.error("Failed to read clipboard content:", err);
        });
    }
  }, []);
  

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }, []);

  const getRowId = useCallback((params) => {
    return isHistory ? params.data.tradeId : params.data.id;
  }, []);

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (isWorkspace || isHistory) {
      if (selectedRows.length > 0) {
        setSelectedRows(selectedRows);
      } else setSelectedRows([]);
    }
  };

  const updateServerSideRow = useCallback(
    (updateBody) => {
      if (gridApi) {
        // Use tradeId to find the corresponding row node
        const rowNode = gridApi.getRowNode(updateBody.tradeId);

        if (rowNode) {
          // Update the row data
          rowNode.setData({ ...rowNode.data, ...updateBody });
        } else {
          console.warn(
            `Row with tradeId ${updateBody.tradeId} not found in the grid`,
          );
        }
      }
    },
    [gridApi],
  );

  const addNewRows = useCallback(() => {

    if (gridApi && isHistory) {
      const datasource = getServerSideDatasource();
      gridApi.setServerSideDatasource(datasource);
    }
  }, [gridApi, isHistory, getServerSideDatasource,gridRef,rowData]);

  const updateBatsResponse = useCallback(
    (batsData) => {
      console.log(batsData,'batsData1')
      console.log(rowData,"rowData1")
      setSelectedRows((prevData) =>
        prevData.map((trade) =>
          trade.trade_report_id === batsData.tradeReportsId
            ? { ...trade, batsResponse: batsData.batsResponse,error:batsData.message,execTime: batsData.transactTime,batsId:batsData.batsId }
            : trade
        )
      );
      setRowData((prevData) =>
        prevData.map((trade) =>
          trade.trade_report_id === batsData.tradeReportsId
            ? { ...trade,batsResponse: batsData.batsResponse,execTime: batsData.transactTime,batsId:batsData.batsId,error:batsData.message}
            : trade
        )
      );

  }, [rowData]);

  const updateBatsStatusResponse = useCallback(
    (batsData) => {
      console.log(batsData,'batsData2')
      console.log(rowData,"rowData2")
      setSelectedRows((prevData) =>
        prevData.map((trade) =>
          trade.trade_report_id === batsData.tradeReportsId
            ? { ...trade, batsResponse: batsData.batsResponse,error:batsData.message,bats_response_id:batsData.batsTradeReportsId }
            : trade
        )
      );
      setRowData((prevData) =>
        prevData.map((trade) =>
          trade.trade_report_id === batsData.tradeReportsId
            ? { ...trade, batsResponse: batsData.batsResponse,error:batsData.message,bats_response_id:batsData.batsTradeReportsId }
            : trade
        )
      );

  }, [rowData]);

  const onCellEditRequest = useCallback(
    (e) => {
      onCellEditReq(
        e,
        gridApi,
        rowData,
        setRowData,
      );
    },
    [gridApi,rowData,setRowData],
  );

  useEffect(() => {
    if (isWorkspace) {
      document.addEventListener("keydown", handlePasteEvent);
    }

    return () => {
      document.removeEventListener("keydown", handlePasteEvent);
    };
  }, [isWorkspace, handlePasteEvent]);

  useEffect(() => {
    if (
      gridApi !== null &&
      (isInstruments || isParticipants || isHistory) &&
      orderBook
    ) {
      const datasource = getServerSideDatasource();
      gridApi.setServerSideDatasource(datasource);
    }
  }, [
    gridApi,
    isWorkspace,
    isInstruments,
    isParticipants,
    isHistory,
    orderBook,
    getServerSideDatasource,
  ]);

  useEffect(() => {
    // Update domLayout based on rowData length
    if (isInstruments || (rowData && rowData.length > 28)) {
      setDomLayout("normal"); // Switch to normal for larger datasets
    } else {
      setDomLayout("autoHeight"); // Auto adjust for smaller datasets
    }
  }, [rowData]);

  useEffect(() => {
    const handleCboe = (message) => {
      switch (message.data.type) {
        case "update_cboe_trade":
          updateServerSideRow(message.data.updateBody);
          break;
        case "new_trades":
            addNewRows(message.data.trades);
          break;
       case "update_trades_status":
          updateBatsStatusResponse(message.data.trades)
         break;
         case "update_trades":
          updateBatsResponse(message.data.trades)
         break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleCboe);
    return () => {
      window.removeEventListener("message", handleCboe);
    };
  }, [updateServerSideRow,rowData,gridApi]);



  return (
    <Grid
      item
      className="custom-grid ag-theme-balham-dark"
      style={gridStyle}
      xs={12}
    >
      <AgGridReact
        ref={gridRef}
        rowData={
          !isInstruments && !isParticipants && !isHistory ? rowData : undefined
        }
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        domLayout={domLayout}
        onGridReady={onGridReady}
        animateRows={true}
        readOnlyEdit={true}
        singleClickEdit={true}
        headerHeight={40}
        cacheBlockSize={
          isInstruments || isParticipants || isHistory ? 50 : undefined
        }
        pagination={true}
        paginationPageSize={50}
        rowModelType={isWorkspace ? "clientSide" : "serverSide"}
        rowSelection={isWorkspace || isHistory ? "multiple" : null}
        onSelectionChanged={
          isWorkspace || isHistory ? onSelectionChanged : null
        }
        rowDeselection={true}
        // onCellKeyDown={onCellKeyDown}
        onCellEditRequest={onCellEditRequest}
        // enableCellEditingOnBackspace={true}
        // getRowClass={getRowClass}
      />
    </Grid>
  );
};
