import React, { useEffect, useState } from "react";
import { fetchParticipants } from "../../api/apiCalls";
import { GenericTable } from "../genericTable/GenericTable";

export const BatsParticipants = ({ orderBook }) => {
  const [participantsList, setParticipantsList] = useState(null);
  const columnDefs = [
    { headerName: "CBOE Participant Code", field: "allegedFirmBankCode" },
    { headerName: "Participant Name", field: "allegedFirm" },
    { headerName: "Participant MPID", field: "allegedFirmMpid" },
    { headerName: "LEI", field: "allegedFirmLei" },
  ];

  return (
    <>
      <GenericTable
        columnDefs={columnDefs}
        isParticipants={true}
        orderBook={orderBook}
      />
    </>
  );
};
