export const onCellEditReq = async (
    e,
    gridApi,
    rowData,
    setRowData,

  ) => {
    const rowIndex = e.node.rowIndex;
    const colId = e.column.colId;
    const value = e.value;

    setRowData((prev) => {
        const prevData = [...prev]
        prevData.splice(rowIndex,1,{...prevData[rowIndex],[`${colId}`]:value})
        return prevData
    })
}
