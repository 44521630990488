import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GenericTable } from "../genericTable/GenericTable";
import { useStyles } from "../../style/style";
import { submitTrades,cancelTrades,amendTrades } from "../../api/apiCalls";
import { useDispatch } from "react-redux";
import { dateFormatter } from "../genericTable/utils/valueFormatters";
import { formatNumber } from "../genericTable/utils/numberFormatter"
export const Workspace = ({ orderBook, tab, rowData, setRowData,tradeType,setTradeType }) => {
  const classes = useStyles({});



  const handleSwitchOrderBook = (e) => {
    const isChecked = e.target.checked; 
    setTradeType(isChecked);
  };

  const columnDefs = [
    { headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 40 }, // Checkbox column
    { headerName: "Buyer", field: "buyer", 
      editable: (params) => {
      return params.data.status === 'New' || params.data.status === "Error" || params.data.batsResponse === "Rejected";
    }, },
    {
      headerName: "Buyer Account",
      field: "buyerAccount",
      minWidth: 110,
      editable: (params) => {
        return params.data.status === 'New' || params.data.status === "Error" || params.data.batsResponse === "Rejected";
      }, 
    },
    { headerName: "Seller", field: "seller",   
      editable: (params) => {
      return params.data.status === 'New' || params.data.status === "Error" || params.data.batsResponse === "Rejected";
    },  },
    {
      headerName: "Seller Account",
      field: "sellerAccount",
      minWidth: 110,
      editable: (params) => {
        return params.data.status === 'New' || params.data.status === "Error" || params.data.batsResponse === "Rejected";
      }, 
    },
    { headerName: "BBG Code", field: "bbgCode",   editable: (params) => {
      return params.data.status === 'New' || params.data.status === "Error" || params.data.batsResponse === "Rejected";
    }, },
    {
      headerName: "Quantity",
      field: "quantity",
      valueFormatter: formatNumber,
      editable:true
    },
    {
      headerName: "Price",
      field: "price",
      minWidth: 100,
      editable:true
    },
    {
      headerName: "Trade Date",
      field: "trade_date",
      minWidth: 120,
      valueFormatter: dateFormatter,
      editable: (params) => {
        return params.data.status === 'New' || params.data.status === "Error";
      }, 
    },
    {
      headerName: "Settlement Date",
      field: "settlementDate",
      minWidth: 120,
      valueFormatter: dateFormatter,
      editable: (params) => {
        return params.data.status === 'New' || params.data.status === "Error";
      }, 
    },
    { headerName: "Trade ID", field: "tradeId" },
    { headerName: "Basket ID", field: "basketId" },
    {
      headerName: "Status",
      field: "status",

      cellRenderer: (params) => {
        const { value, data } = params;
        const errorMessage = data?.error;

        return (
          <Tooltip
            title={
              value?.toLowerCase() === "error" && errorMessage ? (
                <Typography variant="body2" color="white">
                  {errorMessage}
                </Typography>
              ) : (
                ""
              )
            }
            arrow
          >
            <span
              style={{
                color:
                  value?.toLowerCase() === "sent"
                    ? "#22D865"
                    : value?.toLowerCase() === "new"
                    ? "#1CD3FF"
                    : value?.toLowerCase() === "error"
                    ? "#FF4E32"
                    : value.toLowerCase() === "pending" || value.toLowerCase() === "cancel"
                    ? "#F7B24C"
                    : "white",
              }}
            >
              {value}
            </span>
          </Tooltip>
        );
      },
    },
    {
      flex: 1.2,
      minWidth: 150,
      headerName: "Exec Time",
      field: "execTime",
      cellStyle: () => {
        return { color: "#989EA8" };
      },
    },
    { headerName: "BATS ID", field: "batsId" },
    {
      headerName: "BATS Response",
      field: "batsResponse",
      cellRenderer: (params) => {
        const { value, data } = params;
        const errorMessage = data?.error;

        return (
          <Tooltip
          title={
            value === "Rejected" || value === "Declined" && errorMessage ? (
              <Typography variant="body2" color="white">
                {errorMessage}
              </Typography>
            ) : (
              ""
            )
          }
          arrow
        >
          <span
            style={{
              color: value === "Accepted" ? "#22D865" : "#FF4E32",
            }}
          >
            {value}
          </span>
        </Tooltip>
        
        );
      },
      minWidth: 110,
    },
  ];
  const [selectedRows, setSelectedRows] = useState([]);
  const [sendButtonStatus, setSendButtonStatus] = useState(false);
  const [amendButtonStatus, setAmendButtonStatus] = useState(false);
  const dispatch = useDispatch()

  const handleSend = async () => {
   const ids = selectedRows.map(trade => trade.id);
   const filteredTrades = rowData.filter(trade => ids.includes(trade.id));
    try {
      const validateRes = await submitTrades(
        filteredTrades,
        orderBook,
        dispatch,
        setRowData,
        setSelectedRows,
        tradeType
      );
      if (validateRes?.success) {
        console.log("send to python", { validateRes });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = async () => {
    const ids = selectedRows.map(trade => trade.id);
    const filteredTrades = rowData.filter(trade => ids.includes(trade.id));
     try {
       const validateRes = await cancelTrades(
         filteredTrades,
         orderBook,
         dispatch,
         setRowData,
         setSelectedRows,
         tradeType
       );
       if (validateRes?.success) {
         console.log("send to python", { validateRes });
       }
     } catch (error) {
       console.log(error);
     }
  };

  const handleAmend = async () => {
    const ids = selectedRows.map(trade => trade.id);
    const filteredTrades = rowData.filter(trade => ids.includes(trade.id));
     try {
       const validateRes = await amendTrades(
         filteredTrades,
         orderBook,
         dispatch,
         setRowData,
         setSelectedRows,
         tradeType
       );
       if (validateRes?.success) {
         console.log("send to python", { validateRes });
       }
     } catch (error) {
       console.log(error);
     }
  };

  useEffect(() => {
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      const filterBatsResponse = selectedRows.filter(
        (row) => row.batsResponse === "Rejected"
      );
      if(filterBatsResponse.length > 0){
        setSendButtonStatus(true);
      }else {
      const filter = selectedRows.filter(
        (row) => row.status.toLowerCase() !== "new" && row.status !== "Error" && row.status !== "Cancelled"
      );
      setSendButtonStatus(filter.length === 0); // True if all rows are "new"
      }
    } else {
      setSendButtonStatus(false); // False if selectedRows is empty or invalid
    }
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      const filterBatsResponse = selectedRows.filter(
        (row) => row.batsResponse === "Rejected"
      );
      if(filterBatsResponse.length > 0){
        setAmendButtonStatus(false);
      }else {
      const filter = selectedRows.filter(
        (row) => row.status.toLowerCase() !== "sent"
      );
      setAmendButtonStatus(filter.length === 0); // True if all rows are "new"
      }
    } else {
      setAmendButtonStatus(false); // False if selectedRows is empty or invalid
    }
  }, [selectedRows]);

  // useEffect(() => {
  //   if (tab === "workspace") {
  //     setRowData([...responseData]);
  //   }
  //   console.log("mounted");
  //   return () => {
  //     console.log("component unmounted");
  //     setSelectedRows([]);
  //   };
  // }, [tab]);
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        style={{ height: "80px" }}
      >
        {tab === "workspace" ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            style={{height: "10px"}}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ gap: "8px" }} // Adds space between the labels and the switch
            >
              {" "}
              <Typography
                variant="h5"
                style={{
                  color: !tradeType ? "#FFFFFF" : "#989EA8", // Highlight the selected label
                  fontWeight: !tradeType ? "bold" : "normal", // Bold text for the active label
                }}
              >
                Cash
              </Typography>
              <Switch
                checked={tradeType}
                onChange={handleSwitchOrderBook}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
                className={classes.customSwitch}
              />
              <Typography
                variant="h5"
                style={{
                  color: tradeType ? "#FFFFFF" : "#989EA8", // Highlight the selected label
                  fontWeight: tradeType ? "bold" : "normal", // Bold text for the active label
                }}
              >
                EFP
              </Typography>
            </Box>
          </Grid>
        ) : (
          <></>
        )}
        {tab === "workspace" && selectedRows.length === 0 ? (
          <Grid style={{ display: "flex",height: "80px"}}>
            <Typography variant="h5">
              Paste new trades using CTRL + V
            </Typography>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {tab === "workspace" && sendButtonStatus ? (
              <Button
                className={`${classes.btn} ${classes.sendBtn}`}
                onClick={handleSend}>
                Send
              </Button>
            ) : null}
            {amendButtonStatus ? (
              <>
                <Button className={`${classes.btn} ${classes.amendBtn}`}  onClick={handleAmend}>
                  Amend
                </Button>
                <Button className={`${classes.btn} ${classes.cancelBtn}`} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            ) : null}

            {/* <Button className={`${classes.btn} ${classes.deleteBtn}`}>
              Delete
            </Button> */}
          </Grid>
        )}
      </Grid>
      <GenericTable
        rowData={rowData}
        setRowData={setRowData}
        columnDefs={columnDefs}
        isWorkspace={tab === "workspace"}
        isHistory={tab === "history"}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        orderBook={orderBook}
        tradeType={tradeType}
      />
    </>
  );
};
