import axios from "axios";
import * as actionSnackBar from "../../../store/snackbar/action";
import { dateFormatter } from "../components/genericTable/utils/valueFormatters";
export async function fetchInstruments(orderBook, startRow, endRow) {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authorization token is missing");
    }
    const res = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }cboe/bats_instruments/${orderBook.toLowerCase()}?startRow=${startRow}&endRow=${endRow}`,
      { headers: { Authorization: token } },
    );
    if (!res.data || !Array.isArray(res.data.rows)) {
      throw new Error("Invalid API response structure");
    }

    return res.data;
  } catch (error) {
    console.error("Error fetching instruments:", error);

    // Return an empty result to avoid breaking the grid
    return { rows: [], totalCount: 0 };
  }
}
export async function fetchParticipants(orderBook, startRow, endRow) {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authorization token is missing");
    }
    const res = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }cboe/bats_participants/${orderBook.toLowerCase()}?startRow=${startRow}&endRow=${endRow}`,
      { headers: { Authorization: token } },
    );
    if (!res.data || !Array.isArray(res.data.rows)) {
      throw new Error("Invalid API response structure");
    }

    return res.data;
  } catch (error) {
    console.error("Error fetching instruments:", error);

    // Return an empty result to avoid breaking the grid
    return { rows: [], totalCount: 0 };
  }
}
export async function fetchTrades(orderBook, startRow, endRow) {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authorization token is missing");
    }
    const res = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }cboe/trades/${orderBook.toLowerCase()}?startRow=${startRow}&endRow=${endRow}`,
      { headers: { Authorization: token } },
    );
    if (!res.data || !Array.isArray(res.data.rows)) {
      throw new Error("Invalid API response structure");
    }

    return res.data;
  } catch (error) {
    console.error("Error fetching instruments:", error);

    // Return an empty result to avoid breaking the grid
    return { rows: [], totalCount: 0 };
  }
}

export async function submitTrades(trades, orderBook, dispatch, setRowData,setSelectedRows,tradeType) {

  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}cboe/send_trades`,
      { trades, venue: orderBook,tradeType:tradeType },
      { headers: { Authorization: token } },
    );

    if (!res?.data?.success) {
      const errorMessage = res.data.error || "Validation failed";
      const failedTrades = res?.data?.trades || [];
      updateRowDataWithServerResponse(failedTrades, setRowData);
      return { success: false, error: errorMessage };
    }
    updateRowDataWithServerResponse(res?.data?.trades, setRowData);
    setSelectedRows(res?.data?.trades,)

    return { success: true };
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || error.message || "Server error occurred";
    console.error("Validation error:", errorMessage);
    dispatch(actionSnackBar.setSnackBar("error", errorMessage, 3000));

    const failedTrades = error?.response?.data?.trades || [];
    updateRowDataWithServerResponse(failedTrades, setRowData);
    return { success: false, error: errorMessage };
  }
}
export async function cancelTrades(trades, orderBook, dispatch, setRowData,setSelectedRows,tradeType) {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}cboe/cancel_trades`,
      { trades, venue: orderBook,tradeType:tradeType },
      { headers: { Authorization: token } },
    );
    if (!res?.data?.success) {
      const errorMessage = res.data.error || "Validation failed";
      const failedTrades = res?.data?.trades || [];
      updateRowDataWithServerResponse(failedTrades, setRowData);
      return { success: false, error: errorMessage };
    }
    updateRowDataWithServerResponse(res?.data?.trades, setRowData);
    setSelectedRows(res?.data?.trades,)

    return { success: true };
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || error.message || "Server error occurred";
    console.error("Validation error:", errorMessage);
    dispatch(actionSnackBar.setSnackBar("error", errorMessage, 3000));

    const failedTrades = error?.response?.data?.trades || [];
    updateRowDataWithServerResponse(failedTrades, setRowData);
    return { success: false, error: errorMessage };
  }
}

export async function amendTrades(trades, orderBook, dispatch, setRowData,setSelectedRows,tradeType) {
  try {
    const token = sessionStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}cboe/amend_trades`,
      { trades, venue: orderBook,tradeType:tradeType },
      { headers: { Authorization: token } },
    );

    if (!res?.data?.success) {
      const errorMessage = res.data.error || "Validation failed";
      const failedTrades = res?.data?.trades || [];
      updateRowDataWithServerResponse(failedTrades, setRowData);
      return { success: false, error: errorMessage };
    }
    updateRowDataWithServerResponse(res?.data?.trades, setRowData);
    setSelectedRows(res?.data?.trades,)

    return { success: true };
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || error.message || "Server error occurred";
    console.error("Validation error:", errorMessage);
    dispatch(actionSnackBar.setSnackBar("error", errorMessage, 3000));

    const failedTrades = error?.response?.data?.trades || [];
    updateRowDataWithServerResponse(failedTrades, setRowData);
    return { success: false, error: errorMessage };
  }
}

function updateRowDataWithServerResponse(serverTrades, setRowData) {
  setRowData((prevRowData) => {
    // Create a map of updated trades by `tradeId` for faster lookup
    const updatedTradesMap = serverTrades.reduce((acc, trade) => {
      acc[trade.id] = trade;
      return acc;
    }, {});

    // Merge updated trades with the existing rowData
    const newRowData = prevRowData.map((row) => {
      // If the current row's `tradeId` exists in the updated trades map, merge the changes
      if (updatedTradesMap[row.id]) {
        // Exclude batsResponse from the merge by manually spreading the fields except batsResponse
        const { batsResponse, execTime, batsId, ...updatedRow } = updatedTradesMap[row.id]; // Exclude batsResponse, execTime, and batsId

        return {
          ...row, // Keep existing data
          ...updatedRow, // Apply updates from the server except batsResponse
        };
      }
      // Otherwise, keep the row as is
      return row;
    });
    return newRowData;
  });
}

