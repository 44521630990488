import React, { useEffect, useState, useCallback } from "react";
import { Grid, ThemeProvider } from "@material-ui/core";
import { Header } from "../components/Header";
import { TabManager } from "../components/TabsManager";
import { theme } from "../style/style";
import { tabsList } from "../utils/tabsList";
import { TabContent } from "../components/TabContent";
import { closeWS, connectWS, sendEvent, ws } from "../../../services/websocket";
import * as rfqSlice from "../../../store/rfq/rfqSlice";
import { useDispatch } from "react-redux";

export const responseData = [
  {
    id: 1,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "SGDE",
    sellerAccount: "SGDE",
    bbgCode: "AAF LN",
    quantity: 1.2345,
    price: "12,345.00",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: 1234,
    basketId: "ABCD",
    status: "Sent",
    execTime: "12-Nov-2024 12:15:03",
    batsId: "ABCDEFGJ",
    batsResponse: "Accepted",
    venue: "BXE",
  },
  {
    id: 2,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "SGDE",
    sellerAccount: "SGDE",
    bbgCode: "AAL LN",
    quantity: 1.2345,
    price: "12,345.11",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: 1234,
    basketId: "ABCD",
    status: "Sent",
    execTime: "12-Nov-2024 12:15:03",
    batsId: "ABCDEFGJ",
    batsResponse: "Accepted",
    venue: "BXE",
  },
  {
    id: 3,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "MKRE",
    sellerAccount: "SGDE",
    bbgCode: "ABF LN",
    quantity: 6.789,
    price: "67,890.12",
    settlementDate: "2024-12-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "BXE",
  },
  {
    id: 31,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "MKRE",
    sellerAccount: "SGDE",
    bbgCode: "ABF LN",
    quantity: 6.789,
    price: "67,890.13",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "BXE",
  },
  {
    id: 32,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "MKRE",
    sellerAccount: "SGDE",
    bbgCode: "ABF LN",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "BXE",
  },
  {
    id: 34,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "MKRE",
    sellerAccount: "SGDE",
    bbgCode: "ABF LN",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "BXE",
  },
  {
    id: 38,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "MKRE",
    sellerAccount: "SGDE",
    bbgCode: "ABF LN",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-01-10",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "BXE",
  },
  {
    id: 4,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "MKRE",
    sellerAccount: "SGDE",
    bbgCode: "ABF LN",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-12-10",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "BXE",
  },
  {
    id: 5,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "SGDE",
    sellerAccount: "SGDE",
    bbgCode: "ADM LN",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-01-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "Error",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "BXE",
  },
  {
    id: 6,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "SGDE",
    sellerAccount: "SGDE",
    bbgCode: "AAF LN",
    quantity: 1.2345,
    price: "12,345.00",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: 1234,
    basketId: "ABCD",
    status: "Sent",
    execTime: "12-Nov-2024 12:15:03",
    batsId: "ABCDEFGJ",
    batsResponse: "Accepted",
    venue: "DXE",
  },
  {
    id: 7,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "SGDE",
    sellerAccount: "SGDE",
    bbgCode: "AAL LN",
    quantity: 1.2345,
    price: "12,345.00",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: 1234,
    basketId: "ABCD",
    status: "Sent",
    execTime: "12-Nov-2024 12:15:03",
    batsId: "ABCDEFGJ",
    batsResponse: "Accepted",
    venue: "DXE",
  },
  {
    id: 8,
    buyer: "BNAR",
    buyerAccount: "07400YDK",
    seller: "HSOD",
    sellerAccount: "SGDE",
    bbgCode: "00XT GY",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "DXE",
  },
  {
    id: 9,
    buyer: "JMGS",
    buyerAccount: "07400YDK",
    seller: "SECF",
    sellerAccount: "SGDE",
    bbgCode: "00XT GY",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-12-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "New",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "DXE",
  },
  {
    id: 10,
    buyer: "MSMT",
    buyerAccount: "07400YDK",
    seller: "SGDE",
    sellerAccount: "SGDE",
    bbgCode: "00XT GY",
    quantity: 6.789,
    price: "67,890.00",
    settlementDate: "2025-01-30",
    tradeDate: null,
    tradeId: null,
    basketId: null,
    status: "Error",
    execTime: "",
    batsId: "",
    batsResponse: "",
    venue: "DXE",
  },


];
export const CboeCross = () => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");

  const [orderBook, setOrderBook] = useState("BXE");
  const [rowData, setRowData] = useState([]);
  const [tradeType, setTradeType] = useState(true)
  const [selectedTab, setSelectedTab] = useState(
    tabsList[0]?.value || "workspace",
  );

  const filterData = rowData.filter(
    (row) => row?.venue?.toLowerCase() === orderBook?.toLowerCase(),
  );
  const updateTradeStatus = useCallback(
    async (updateBody) => {
      setRowData((pre) => {
        const arr = [...pre];
        const index = arr.findIndex(
          (row) => row.tradeId === updateBody.tradeId,
        );
        console.log({ index });
        if (index !== -1) {
          arr.splice(index, 1, { ...arr[index], ...updateBody });
        }
        console.log({ arr });
        return arr;
      });
    },
    [rowData],
  );

  useEffect(() => {
    if (ws !== null) {
      sendEvent({
        type: "cboe",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""), dispatch, rfqSlice);

      sendEvent({
        type: "cboe",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    }

    return () => {
      sendEvent({
        type: "unsubscribe_cboe",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    };
  }, []);

  useEffect(() => {
    const handleCboe = async (message) => {
      switch (message.data.type) {
        case "update_cboe_trade":
          updateTradeStatus(message.data.updateBody);
          break;

        default:
          break;
      }
    };

    window.addEventListener("message", handleCboe);

    return () => {
      window.removeEventListener("message", handleCboe);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        style={{
          color: "white",
        }}
      >
        <Header orderBook={orderBook} setOrderBook={setOrderBook} />
        <Grid item xs={12}>
          <TabManager
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Grid>
        <TabContent
          selectedTab={selectedTab}
          orderBook={orderBook}
          rowData={filterData}
          setRowData={setRowData}
          tradeType={tradeType}
          setTradeType={setTradeType}
        />
      </Grid>
    </ThemeProvider>
  );
};
