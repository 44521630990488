import React from "react";
import { Workspace } from "./workspace/Workspace";
import { BatsInstruments } from "./batsInstruments/BatsInstruments";
import { BatsParticipants } from "./batsParticipants/BatsParticipants";

export const TabContent = ({ selectedTab, orderBook, rowData,setRowData, tradeType,setTradeType }) => {
  switch (selectedTab) {
    case "workspace":
      return (
        <Workspace
          orderBook={orderBook}
          tab={"workspace"}
          key={"workspace"}
          rowData={rowData}
          setRowData={setRowData}
          tradeType={tradeType}
          setTradeType={setTradeType}
        />
      );
    case "tradeHistory":
      return (
        <Workspace orderBook={orderBook} tradeType={tradeType} tab={"history"} key={"history"} />
      );
    case "batsInstruments":
      return <BatsInstruments orderBook={orderBook} key={"batsInstruments"} />;
    case "batsParticipants":
      return (
        <BatsParticipants orderBook={orderBook} key={"batsParticipants"} />
      );
    default:
      return null;
  }
};
